<template>
  <div v-if="value">
    <el-popover
      v-for="(button, index) in value"
      :key="index"
      placement="bottom"
      width="600"
      :trigger="editable ? 'click' : 'manual'"
      @hide="quitEditButton(index)"
    >
      <el-form
        style="overflow-y: auto; max-height: 500px"
        :ref="`form${index}`"
        :rules="rules"
        :model="value[index]"
        @submit.native.prevent
      >
        <el-form-item prop="text">
          <el-input v-model="button.text" type="text">
            <template slot="prepend">Text</template>
          </el-input>

          <SynonymTable v-if="button.synonym" :value="button" />

          <!-- BUTTONS -->
          <el-button
            type="success"
            size="small"
            icon="el-icon-plus"
            @click="addSynonym(button, index)"
            style="
              position: absolute;
              right: 54px;
              top: 50%;
              transform: translateY(-50%);
            "
          />

          <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            @click="value.splice(index, 1)"
            style="
              position: absolute;
              right: 6px;
              top: 50%;
              transform: translateY(-50%);
            "
          />
        </el-form-item>
        <!-- TABS -->
        <el-row style="text-align: center">
          <el-radio-group v-model="button.active">
            <el-radio-button
              v-for="(buttonType, buttonTypeIndex) in buttonTypes"
              :key="`button-${index}-type-${buttonTypeIndex}`"
              :label="buttonType.name"
              >{{ buttonType.label }}</el-radio-button>
          </el-radio-group>
        </el-row>

        <el-divider>Configurations</el-divider>

        <component
          :is="eventComponent(button, index)"
          v-if="eventComponentIsValid(button, index)"
          v-model="value[index]"
          @save-side-effect="$emit('save-side-effect')"
        ></component>
      </el-form>

      <div slot="reference" style="position: relative">
        <i
          v-if="isInvalid(button, index)"
          class="el-icon-warning"
          style="
            position: absolute;
            left: 5px;
            z-index: 1;
            color: #fa5454;
            font-size: 1.8em;
            top: 50%;
            transform: translateY(-50%);
          "
        />
        <el-button
          :class="{ 'bubble-button-invalid': isInvalid(button, index) }"
          class="bubble-button"
          style="position: relative"
          @click.capture="editButton(index, $event)"
        >
          <el-badge v-if="button.synonym" is-dot type="warning">
            <span>{{ button.text || "Button" }}</span>
          </el-badge>
          <span v-else>{{ button.text || "Button" }}</span>

          <shortcut-action
            :button="button"
            v-if="editable && !edit_buttons[index]"
          ></shortcut-action>
        </el-button>
      </div>
    </el-popover>
    <el-button
      v-if="editable && value.length < limit"
      class="bubble-button"
      style="border-style: dashed"
      @click="$emit('addButton')"
    >
      <span>+ Add Button</span>
    </el-button>
  </div>
</template>

<script>
import { BUTTON_TYPES } from "./ButtonTypes/Index";
import Validations from "./Validations";
import ShortcutAction from "./ShortcutAction";
import SynonymTable from "./Synonym";
import ButtonTypeWebsite from "./ButtonTypes/Website";
import ButtonTypePhone from "./ButtonTypes/Phone";
import ButtonTypeEvent from "./ButtonTypes/Event";
import ButtonTypeMicrosite from "./ButtonTypes/Microsite";

export default {
  name: "Buttons",
  mixins: [Validations], // Handle all validation logic
  components: {
    ShortcutAction,
    SynonymTable,
    ButtonTypeWebsite,
    ButtonTypePhone,
    ButtonTypeEvent,
    ButtonTypeMicrosite,
  },
  props: {
    limit: {
      type: Number,
      default() {
        return 3;
      },
    },
    editable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      default() {
        return [];
      },
    }
  },
  data() {
    return {
      edit_buttons: [],
    };
  },
  computed: {
    buttonTypes() {
      return Object.values(BUTTON_TYPES);
    }
  },
  methods: {
    /**
     * @description add in synonym variable if doesnt exists or append if variable exists
     * @param {button} button
     * @param {buttonIndex} number
     * @return {void}
     */
    addSynonym(button, buttonIndex) {
      if (!button) {
        return;
      }

      const synonymExists = !!button.synonym;
      if (synonymExists) {
        button.synonym.push(`synonym`);
      } else {
        this.$set(button, "synonym", ["synonym1"]);
      }
      this.$set(this.value, buttonIndex, button);
    },
    /**
     * @description Check if component is valid / imported before render
     * @param {string} name
     * @return {boolean}
     */
    eventComponentIsValid(btn, index) {
      const isExist = _.has(
        this.$options.components,
        this.eventComponent(btn, index)
      );
      return isExist;
    },

    /**
     * @description Computed EventType component for rendering
     * @param {string} name
     * @return {string}
     */
    eventComponent(btn, index) {
      const name = btn.active || btn.type;
      if (_.isEmpty(btn.active)) {
        this.$set(this.value[index], "active", btn.type);
      }
      
      const _capitalize = _.capitalize(name);
      const _componentName = `ButtonType${_capitalize}`;
      return _componentName;
    },

    editButton(index, event) {
      if (this.editable) {
        this.edit_text = false;
        this.edit_buttons.fill(false);
        this.edit_buttons[index] = true;
        this.$forceUpdate();
      }
    },

    quitEditButton(index) {
      this.edit_buttons[index] = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.bubble-button {
  width: 100%;
  color: #0084ff;
  /* font-size: 1.2em; */
}
.bubble-button-invalid {
  /* background: #F56C6C; */
  border-color: #f56c6c;
  /* font-size: 1.2em;  */
}
</style>
