<template>
  <el-row>
    <p>
      Format must have "+" prefix followed by the country code, area code and
      local number.
      <br />For example, +6583918492
    </p>
    <el-form-item prop="phone">
      <el-input v-model="value.phone">
        <template slot="prepend">Phone Number</template>
      </el-input>
    </el-form-item>
  </el-row>
</template>

<script>
import _ from "lodash";

import { BUTTON_TYPES } from "./Index";

const FIELDS = ["active", "type", "text", "phone", "data", "synonym"];

export default {
  name: "ButtonTypePhone",
  props: ["value"],
  watch: {
    value: {
      deep: true,
      handler(coming, prev) {
        this.value.data = null;
        this.value.type = BUTTON_TYPES[this.$options.name].name;
        for (let key in this.value) {
          if (!_.includes(FIELDS, key)) {
            delete this.value[key];
          }
        }
      },
    },
  },
};
</script>
