<template>
  <el-row>
    <el-row>
      <el-radio-group v-model="value.webview_height_ratio" size="small">
        <el-radio-button label="compact">Compact</el-radio-button>
        <el-radio-button label="tall">Tall</el-radio-button>
        <el-radio-button label="full">Full</el-radio-button>
      </el-radio-group>
    </el-row>
    <el-row>
      <el-tooltip
        effect="dark"
        content="Automatically redirect to URL when node is shown"
        placement="top"
      ></el-tooltip>
      <el-tooltip effect="dark" content="Open link in new tab" placement="top">
        <el-checkbox
          v-model="value.popup"
          style="margin-right: 10px; margin-left: 0px"
          >New Tab</el-checkbox
        >
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="Track Url into analytics"
        placement="top"
      >
        <el-checkbox
          v-model="value.trackUrl"
          style="margin-right: 10px; margin-left: 0px"
          >Track URL</el-checkbox
        >
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="Use Facebook Messenger Webview"
        placement="top"
      >
        <el-checkbox
          v-model="value.messenger_extensions"
          style="margin-right: 10px; margin-left: 0px"
          >Messenger Webview</el-checkbox
        >
      </el-tooltip>
    </el-row>
    <el-row>
      <el-form-item prop="url">
        <el-input v-model="value.url">
          <template slot="prepend">Website URL</template>
        </el-input>
      </el-form-item>
    </el-row>
  </el-row>
</template>

<script>
import _ from "lodash";

import { BUTTON_TYPES } from "./Index";

const FIELDS = [
  "active",
  "type",
  "text",
  "webview_height_ratio",
  "messenger_extensions",
  "url",
  "trackUrl",
  "popup",
  "data",
  "synonym",
];

export default {
  name: "ButtonTypeWebsite",
  props: ["value"],
  watch: {
    value: {
      deep: true,
      handler(coming, prev) {
        this.value.data = null;
        this.value.type = BUTTON_TYPES[this.$options.name].name;
        for (let key in this.value) {
          if (!_.includes(FIELDS, key)) {
            delete this.value[key];
          }
        }
      },
    },
  },
};
</script>
