<template>
  <el-row>
    <EventNodeSelector
      v-model="value"
      @save-side-effect="$emit('save-side-effect')"
    />
  </el-row>
</template>

<script>
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import _ from "lodash";

import { BUTTON_TYPES } from "./Index";

const FIELDS = [
  "active",
  "type",
  "text",
  "data",
  "event",
  "synonym",
];

export default {
  components: {
    EventNodeSelector
  },
  name: "ButtonTypeEvent",
  props: ["value"],
  watch: {
    value: {
      deep: true,
      handler(coming, prev) {
        this.value.type = BUTTON_TYPES[this.$options.name].name;
        for (let key in this.value) {
          if (!_.includes(FIELDS, key)) {
            delete this.value[key];
          }
        }
      }
    }
  }
};
</script>
